import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import get from "lodash/get";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import SettingService from "./SettingService";

class Service extends Resources {
  route = "module";
  getAllRoute = "get-form-module";

  //Without auth route
  getAllModulesRoute = "formNames";
  modules = null;
  getDynamicForm = "form/all-forms";
  steps = "form/show/";

  allForms = null;
  isLoading = false;
  constructor() {
    super(arguments);
  }

  getAll() {
    return SC.getCall({
      url: this.getAllRoute,
    });
  }

  getDynamicForms = async () => {
    if (this.allForms) {
      return this.allForms;
    }

    this.isLoading = true;

    try {
      const res = await SC.getCall({
        url: this.getDynamicForm,
      });
      this.isLoading = false;

      if (res.data.data) {
        this.allForms = res.data.data.map((f) => {
          f.label = f.name;
          f.value = f._id;
          return f;
        });
        return this.allForms;
      }
    } catch (error) {}

    return [];
  };

  getSteps = async (id) => {
    try {
      const res = await SC.getCall({
        url: this.steps + id,
      });
      return res.data.data;
    } catch (e) {}
  };

  getAllModules = async (force = false) => {
    if (this.modules && !force) {
      return this.modules;
    }

    try {
      const res = await SC.getCall({
        url: this.getAllModulesRoute,
      });

      if (res?.data?.settings) {
        SettingService.setSetting(res.data.settings);
      }

      if (res.data.data) {
        this.modules = res.data.data;
      }

      return this.modules;
    } catch (e) {
      console.log(e, "=> ModileService getAllModules");
      return [];
    }
  };

  get disableRoutes() {
    return this.modules
      ?.filter?.((module) => !module.active)
      ?.map?.((m) => m.slug);
  }

  getSteperForms = () => {
    return this.modules.filter((mod) => mod.form_options && mod.active);
  };

  getArabicMessages = () => {
    if (this.modules?.length) {
      return this.modules.reduce((allArabic, { form_options }) => {
        if (form_options) {
          let moduleFormArabic = Object.entries(form_options)
            .map(([stepName, questions]) => questions)
            .flat()
            .filter((question) => question?.arabic && question?.title)
            .reduce((result, question) => {
              result[question.title] = question?.arabic;
              return result;
            }, {});

          return { ...allArabic, ...moduleFormArabic };
        }

        return allArabic;
      }, {});
    }

    return {};
  };

  getBySlug(slug) {
    let modules = this.modules || [];
    return cloneDeep(
      get(
        modules.filter((module) => module.slug === slug),
        "0"
      )
    );
  }

  getNameBySlug(slug) {
    if (!slug) return null;
    return this.getBySlug(slug)?.name || startCase(slug);
  }

  getFormOptionsBySlug(slug) {
    let fo = this.getBySlug(slug);
    return cloneDeep(fo?.form_options || {});
  }

  getFormHeaders = (slug) => {
    let form_options = this.getFormOptionsBySlug(slug);
    delete form_options.identification;

    return Object.values(form_options)
      .flatMap((i) => i)
      .map(({ attribute, ranking, title }) => {
        if (title) {
          let attr = [
            {
              title: ranking ? ranking + " : " + title : title,
              dataIndex: attribute,
              cell: (row) => {
                if (Array.isArray(row)) {
                  return row.join(", ");
                }

                return row;
              },
            },
          ];

          if (!["note", "image", "attachment"].includes(attribute)) {
            attr.push(
              {
                title: "(Reason)" + title,
                dataIndex: attribute + "_other",
              },
              {
                title: "(Attachment)" + title,
                dataIndex: attribute + "_file",
                cell: (row) => {
                  if (Array.isArray(row)) {
                    return row.join(", ");
                  }

                  return row;
                },
              }
            );
          }
          return attr;
        }
      })
      .filter((i) => i)
      .flatMap((i) => i);
  };

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      { title: "Slug", dataIndex: "slug" },
      { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData(data) {
    return {
      headers: this.getHeaders(),
      data,
    };
  }

  assignModule = (user_id, module_id) => {
    let payload = {
      user_id,
      module_id,
    };
    return SC.postCall({ url: `module-attach`, data: payload });
  };

  getModuleIdBySlug = (slug) => {
    return this.modules.find((m) => m.slug === slug)?._id;
  };

  get currentFormSlug() {
    return window.location.pathname.split("/")[1];
  }

  get currentFormName() {
    return this.getNameBySlug(this.currentFormSlug);
  }
}
const ModuleService = new Service();
export default ModuleService;
